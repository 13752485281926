import React from "react";
import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_titl_component_onbd_s_item = [],
        field_text_comp_onbd_s_item = [],
        field_link_component_onbd_s_item = [],
        field_img_component_onbd_s_item = [],
    } = data || {};

    const [title] = field_titl_component_onbd_s_item;
    const [text] = field_text_comp_onbd_s_item;
    const [link] = field_link_component_onbd_s_item;
    const uri = field_link_component_onbd_s_item[0]?.uri;

    const [ imgData ] = field_img_component_onbd_s_item;
    return (
        <div className="col-md-3 onboard-item">
            <a href={uri}>
            {imgData !== undefined &&
                <DrupalImage { ...imgData } />
            }
            {title !== undefined &&
                <h6>{title.value}</h6>
            }
            {text !== undefined &&
                <div dangerouslySetInnerHTML={{
                    __html: text.value
                }}></div>
            }
            {link !== undefined &&
                // <a href={link.uri}>{link.title}</a>
                <p className="onboard-item-link">{link.title}</p>
            }
            </a>
        </div>
    )
}
