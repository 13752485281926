import React from "react";

import SelectList from "./SelectList";
import DrupalFile from "./DrupalFile";

import resetImg from '../images/reset-btn-img.png';

export default ({ data }) => {
    const {
        field_file_component_file_table = [],

    } = data || {};
    const items = field_file_component_file_table.map(
      (data, i) => (data && data.target_id) ? <DrupalFile target_id={data.target_id} key={i} /> : null
    );

    return (
        <section className="container select-form">

            <div className="row">
                <div className="col-md-12">
                    {/*<form action="#">*/}
                    {/*    <SelectList def={"Select a Product"} option={"test"} />*/}
                    {/*    <SelectList def={"Select a Language"} option={"test"} />*/}
                    {/*    <SelectList def={"Type"} option={"test"} />*/}
                    {/*    <div className="reset">*/}
                    {/*        <img src={resetImg}/>*/}
                    {/*        <input className="submit-btn" type="submit" value="Reset"/>*/}
                    {/*    </div>*/}
                    {/*</form>*/}
                    {items}
                </div>
            </div>
        </section>

    );
}
