import React from "react"
import { Helmet } from "react-helmet"
import {graphql, useStaticQuery} from "gatsby";

function OneTrust() {

    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            envName
            oneTrust
          }
        }
      }
    `
    )
    const oneTrust = site.siteMetadata?.oneTrust;

    if (!oneTrust) {
        return null;
    }
const src = `https://cdn.cookielaw.org/consent/${oneTrust}/OtAutoBlock.js`;
  return (
    <Helmet>
      <script type="text/javascript"
              src={src}></script>
      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8"
              data-domain-script={oneTrust}></script>
      <script type="text/javascript">
          {`
                function OptanonWrapper() {}
          `}
      </script>
    </Helmet>
  )
}

export default OneTrust
