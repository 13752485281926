import React from "react";

import VihiclesItem from "../components/VihiclesItem"

export default ({ data }) => {

    const {
        field_items__component_sub_cat = [],
    } = data || {};

    const items = field_items__component_sub_cat.map((data, i) => <VihiclesItem key={i} data={data} />);

    return (
        <section className="onboard-section-item vehicles-items">
            <div className="container onboard-items">
                <div className="row">
                    {items}
                </div>
            </div>
        </section>
    );
}