import React from "react"
import DrupalImage from "./DrupalImage";

export default({ data }) => {
    const {
        field_title_component_pr_sys_it = [],
        field_text_comp_pr_sys_it = [],
        field_link_component_pr_sys_it = [],
        field_img_component_pr_sys_it = [],
        field_width_size_component_pr_sy = [],
    } = data || {};

    const [title] = field_title_component_pr_sys_it;
    const [text] = field_text_comp_pr_sys_it;
    const [link] = field_link_component_pr_sys_it;
    const uri = field_link_component_pr_sys_it[0]?.uri;
    //const [{ value:width = '' }] = field_class_component_pr_sys_it;
    let width = '';
    if(field_width_size_component_pr_sy[0]){
        width = field_width_size_component_pr_sy[0].value;
    }else {
        width = 'col-md-12';
    }

    const [ imgData ] = field_img_component_pr_sys_it;
    return (
        <a className={width} href={uri}>
            {imgData !== undefined &&
                <DrupalImage { ...imgData } />
            }
            <div className='text'>
                {title !== undefined &&
                    <h6>{title.value}</h6>
                }
                {text !== undefined &&
                    <div dangerouslySetInnerHTML={{
                        __html: text.value
                    }}></div>
                }
                {link !== undefined &&
                    <a href={link.uri}>{link.title}</a>
                }
            </div>
        </a>
    )
}

