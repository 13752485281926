import React from "react";
import Slider from 'react-slick';
import SliderItem from "./SliderItem";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default ({ data }) => {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            },
        ]
    };
    const {
        field_item_component_slider = [],
    } = data || {};

    const items = field_item_component_slider.map((data, i) => <SliderItem key={i} data={data} />);
    return (
        <section className="slider">
            <div className="container">
                <div className="row">
                    <Slider {...settings}>
                        {items}
                    </Slider>
                </div>
            </div>
        </section>

    );
}