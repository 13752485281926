import React from "react";

import SalesOfficesItem from "./SalesOfficesItem";
import {graphql, useStaticQuery} from "gatsby";

export default ({ data }) => {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            envName
          }
        }
      }
    `
    )
    const envName = site.siteMetadata?.envName;

    const {
        field_items__component_sal_of = [],
    } = data || {};

    const items = field_items__component_sal_of.map((data, i) => <SalesOfficesItem key={i} data={data} envName={envName} />);
    const containerClassName = envName === 'si' ? 'container sales-offices sales-offices-si' : 'container sales-offices';
    return (
        <section className={containerClassName}>
                <div className="row row-items">
                    {items}
                    {/*<SalesOfficesItem title={"Headquarters - UK"} text={"Airedale House: Canal Road Bladford BD2 1AG" } ph={"+44 (0) 1274 771177"} fax={"+44 (0) 1274 771178"} link={"#"}/>*/}
                    {/*<SalesOfficesItem title={"Ireland"} text={"Unit 23 Primeside Park Kilshane Way Ballycoolin Dublin 15" } ph={"+353 (0) 18612275"} fax={"+353 (0) 18612217"} link={"#"}/>*/}
                    {/*<SalesOfficesItem title={"France"} text={"Za du Champ du Caillou: 10 Rue de Gally 78450 Chavenay" } ph={"(+33) 1 30 799 700"} fax={"(+33) 1 30 799 705"} link={"#"}/>*/}
                </div>
        </section>
    );
}