import React from "react";

import RepresentativesItem from "./RepresentativesItem";


export default ({ data }) => {
    const {
        field_items__component_represent = [],
    } = data || {};

    const items = field_items__component_represent.map((data, i) => <RepresentativesItem key={i} data={data} />);
    return (
        <section className="container sales-offices representatives">
                <div className="row row-items">
                    {items}
                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Airedale House: Canal Road Bladford BD2 1AG" } ph={"+44 (0) 1274 771177"} fax={"+44 (0) 1274 771178"} link={"#"} />*/}
                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Unit 23 Primeside Park Kilshane Way Ballycoolin Dublin 15" } ph={"+353 (0) 18612275"} fax={"+353 (0) 18612217"} link={"#"}/>*/}
                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Za du Champ du Caillou: 10 Rue de Gally 78450 Chavenay" } ph={"(+33) 1 30 799 700"} fax={"(+33) 1 30 799 705"} link={"#"}/>*/}

                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Airedale House: Canal Road Bladford BD2 1AG" } ph={"+44 (0) 1274 771177"} fax={"+44 (0) 1274 771178"} link={"#"} />*/}
                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Unit 23 Primeside Park Kilshane Way Ballycoolin Dublin 15" } ph={"+353 (0) 18612275"} fax={"+353 (0) 18612217"} link={"#"}/>*/}
                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Za du Champ du Caillou: 10 Rue de Gally 78450 Chavenay" } ph={"(+33) 1 30 799 700"} fax={"(+33) 1 30 799 705"} link={"#"}/>*/}

                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Airedale House: Canal Road Bladford BD2 1AG" } ph={"+44 (0) 1274 771177"} fax={"+44 (0) 1274 771178"} link={"#"} />*/}
                    {/*<RepresentativesItem img={logo} title={"Name"} text={"Unit 23 Primeside Park Kilshane Way Ballycoolin Dublin 15" } ph={"+353 (0) 18612275"} fax={"+353 (0) 18612217"} link={"#"}/>*/}
                </div>
        </section>
    );
}