import React from "react";
import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_title_component_repre_it = [],
        field_text_comp_repre_it = [],
        field_link_component_repre_it = [],
        field_ph_component_repre_it = [],
        field_fax_component_repre_it = [],
        field_img_component_repre_it = [],
    } = data || {};

    const [title] = field_title_component_repre_it;
    const [text] = field_text_comp_repre_it;
    const [link] = field_link_component_repre_it;
    const [ph] = field_ph_component_repre_it;
    const [fax] = field_fax_component_repre_it;

    const [ imgData ] = field_img_component_repre_it;
    return (
        <div className="col-md-3">
            <div className="representatives-item">
                {imgData !== undefined &&
                    <DrupalImage { ...imgData } />
                }
                {title !== undefined &&
                    <h2>{title.value}</h2>
                }
                {text !== undefined &&
                    <div className="representatives-item-text" dangerouslySetInnerHTML={{
                    __html: text.value
                }}></div>
                }
                {ph !== undefined &&
                    <p>TEL: {ph.value}</p>
                }
                {fax !== undefined &&
                    <p className="representatives-item-fax">FAX: {fax.value}</p>
                }
                {link !== undefined &&
                    <p>Web: <a className="representatives-item" href={link.uri}>{link.title}</a></p>
                }
            </div>
        </div>
    )
}
