import React from "react";

import ProducrsSystemItem from "../components/ProducrsSystemItem";


export default ({ data }) => {
    const {
        field_title_component_prod_syst = [],
        field_items_component_prod_sys = [],
    } = data || {};

    const [title] = field_title_component_prod_syst;
    const items = field_items_component_prod_sys.map((data, i) => <ProducrsSystemItem key={i} data={data} />);
    return (
        <section className="container products-systems">

            {title !== undefined &&
                <div className="row products-systems-title">
                    <h2>{title.value}</h2>
                </div>
            }


            <div className="row products-systems-items">
                {items}
            </div>
        </section>

    );
}