import React from "react"

import DrupalImage from "./DrupalImage";
export default ({ data }) => {
    const {
        field_title_component_prod_cont	 = [],
        field_text_img_comp_prod_cont = [],
        field_text_component_prod_cont = [],
        field_sub_t_component_prod_cont = [],
        field_link_component_prod_cont = [],
        field_img_component_prod_cont = [],
    } = data || {};

    const [title] = field_title_component_prod_cont;
    const [text] = field_text_component_prod_cont;
    const [text_i ] = field_text_img_comp_prod_cont;
    const [sub_t] = field_sub_t_component_prod_cont;
    const uri = field_link_component_prod_cont[0]?.uri;
    const [ imgData ] = field_img_component_prod_cont;

    return (
        <section className="container product-contact">
            <div className="row">
                <div className="col-md-6 product-contact-img">
                    {imgData !== undefined &&
                        <a href={uri}><DrupalImage {...imgData} style={{height: 'auto'}}/></a>
                    }
                    {text_i !== undefined &&
                    <div dangerouslySetInnerHTML={{
                        __html: text_i.value
                    }}></div>
                    }
                </div>
                <div className="col-md-6 product-contact-text">
                    {text !== undefined &&
                        <div dangerouslySetInnerHTML={{
                        __html: text.value
                    }}></div>
                    }
                    {title !== undefined &&
                        <a href={uri}><h3>{title.value}</h3></a>
                    }
                    {sub_t !== undefined &&
                        <div className="product-sub-text" dangerouslySetInnerHTML={{
                        __html: sub_t.value
                    }}></div>
                    }
                </div>
            </div>
        </section>
    )
}

