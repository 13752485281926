import React from "react"
import DrupalImage from "./DrupalImage";

export default ({ data }) => {

    const {
        field_title_component_col_it = [],
        field_text_comp_col_it = [],
        field_link_component_col_it = [],
        field_img_component_col_it = [],
    } = data || {};

    const [title] = field_title_component_col_it;
    const [text] = 	field_text_comp_col_it;
    const uri = field_link_component_col_it[0]?.uri;

    const [ imgData ] = field_img_component_col_it;
    return (
        <div className="col-md-6 vehicle-onboard-item">
            {imgData !== undefined &&
                <a href={uri}><DrupalImage {...imgData} /></a>
            }
            {title !== undefined &&
                <a href={uri}><h6>{title.value}</h6></a>
            }
            {text !== undefined &&
                <div dangerouslySetInnerHTML={{
                    __html: text.value
                }}></div>
            }
        </div>
    )
}
