import React from "react";
import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_img_component_banner_img = [],
    } = data || {};
    const [ imgData ] = field_img_component_banner_img;
    return (
        <section className="mein-banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 banner-img">
                        {imgData !== undefined &&
                            <DrupalImage { ...imgData } />
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
