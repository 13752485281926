import React from "react";

function SelectList({ def, option }) {
    return (
        <select>
            <option value="0">{def}</option>
            <option value="1">{option}</option>
        </select>
    );
}
export default SelectList