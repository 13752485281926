import React from "react"

export default ({ data, filter }) => {
    const {
        field_answer_comp_faq_it	 = [],
        field_question_component_faq_it = [],
    } = data || {};

    filter = filter.toLowerCase();

    const title = field_question_component_faq_it[0]?.value || '';
    const text = field_answer_comp_faq_it[0]?.value || '';

    const textClean = text.replace(/<[^>]*>?/gm, '');
    const _filter = () =>
      !filter || (title.toLowerCase().includes(filter) || textClean.toLowerCase().includes(filter));

    return _filter() ? (
        <div className="faq-item">
            {title && <h3>{title}</h3>}
            {textClean !== undefined &&
                <div dangerouslySetInnerHTML={{
                    __html: text
                }}></div>
            }
        </div>
    ) : null;
}

