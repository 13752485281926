import React from "react";


export default ({ data }) => {
    const {
        field_title_component_subtitle = [],
        field_title_component_title = [],
        field_title_component_des = [],
    } = data || {};

    const [title] = field_title_component_title;
    const [subtitle] = field_title_component_subtitle;
    const [description] = field_title_component_des;

    return (
        <section className="onboard-section-title">
            <div className="container onboard-title">
                <div className="row">
                    <div className="col-md-12">
                        {title !== undefined &&
                            <h1>{ title.value }</h1>
                        }
                        {subtitle !== undefined &&
                            <div dangerouslySetInnerHTML={{
                                __html: subtitle.value
                            }}></div>
                        }
                        {description !== undefined &&
                        <div dangerouslySetInnerHTML={{
                            __html: description.value
                        }}></div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
