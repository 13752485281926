import React from "react"


export default({ data }) => {
    const {
        body = [],
        field_news_date = [],
        field_disclaimer = [],
        field_news_links = [],
        field_resorces_links = [],
    } = data || {};

    const [date] = field_news_date;
    const [text] = body;
    const [disclaimer] = field_disclaimer;
    const resorces_links = field_resorces_links.map((data, i) => <a key={i} href={field_resorces_links[i].uri} >{field_resorces_links[i].title}</a>);
    const news_links = field_news_links.map((data, i) => <a key={i} href={field_news_links[i].uri} >{field_news_links[i].title}</a>);

     return (
        <section className="container news-single">
            <div className="row">
                <div className='col-md-3 news-single-resources'>
                    <h3>Resources</h3>
                    {resorces_links}
                </div>
                <div className='col-md-9'>
                    <div className="news-single-befor-title">
                        {date !== undefined &&
                            <span>{date.value}</span>
                        }
                        {news_links}
                    </div>
                    {text !== undefined &&
                    <div
                        dangerouslySetInnerHTML={{
                            __html: text.value
                        }}></div>
                    }
                    {disclaimer !== undefined &&
                    <div className="news-single-bottom-par" dangerouslySetInnerHTML={{
                        __html: disclaimer.value
                    }}></div>
                    }
                </div>
            </div>
        </section>
    )
}

