import React from "react";

import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_title_component_oload_prot = [],
        field_text_comp_oload_prote = [],
        field_link_component_oload_prote = [],
        field_img_component_oload_prote = [],
    } = data || {};

    const [title] = field_title_component_oload_prot;
    const [text] = field_text_comp_oload_prote;
    const [link] = field_link_component_oload_prote;

    const [ imgData ] = field_img_component_oload_prote;
    return (
        <section className="container overload-protection">
            <div className="row">
                <div className="col-md-6 overload-protection-img">
                    {imgData !== undefined &&
                        <DrupalImage { ...imgData } />
                    }
                </div>
                <div className="col-md-6 overload-protection-text">
                    {title !== undefined &&
                        <h2>{title.value}</h2>
                    }
                    {text !== undefined &&
                        <div dangerouslySetInnerHTML={{
                            __html: text.value
                        }}></div>
                    }
                    {link !== undefined &&
                        <a href={link.uri}>{link.title}</a>
                    }
                </div>
            </div>
        </section>
    );
}