import React from "react"
import ProductHighlihtsItems from "./ProductHighlihtsItems";

export default ({ data }) => {
    const {
        field_items_component_colums = [],
    } = data || {};

    const items = field_items_component_colums.map((data, i) => <ProductHighlihtsItems key={i} data={data} />);
    return (
        <section className="container product-highlihts">
            <div className="row">
                {items}
            </div>
        </section>
    )
}

