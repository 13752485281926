import React, { useState, useEffect } from "react";
import axios from 'axios';
import DrupalImage from "./DrupalImage";
const { GATSBY_API_URL = 'http://localhost' } = process.env;

export default () => {
    const [views, setViews] = useState([]);

    useEffect(() => {
        //axios.get(`${GATSBY_API_URL}/news/news-page` )
        axios.get(`${GATSBY_API_URL}/news/news-page?category=news` )
            .then(res => {
                setViews(res.data);
            })
    },[axios, setViews]);
    const items = views.map((item, i) => {
        const href = item.view_node;
        const img = item.field_img_prev;
        const title = item.title.replace(/^(.{70}[^\s]*).*/, "$1");
        return (
            <div className="col-md-4">
                {img &&
                    <img src={GATSBY_API_URL + img}/>
                }
                <a href={href}>{title}</a>
            </div>
        )
    });
    return  items
}
