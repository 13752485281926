import React from "react";

import ContactFormElement from "./ContactFormElement";

function ContactForm({ title, bgcolor }) {
    return (
        <section className="contact-form" style={{backgroundColor: bgcolor}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <h2>{title}</h2>
                        <form action="#">
                            <ContactFormElement title={"Name"} id={"name"} name={"name"} nameclass={"contact-el required"} type={"text"} />
                            <ContactFormElement title={"Email"} id={"email"} name={"email"} nameclass={"contact-el required"} type={"text"} />
                            <ContactFormElement title={"Country"} id={"country"} name={"country"} nameclass={"contact-el required"} type={"text"} />
                            <ContactFormElement title={"Phone"} id={"phone"} name={"phone"} nameclass={"contact-el"} type={"text"} />
                            <ContactFormElement title={"Company"} id={"company"} name={"company"} nameclass={"contact-el"} type={"text"} />
                            <div className="text-area contact-el required">
                                <label htmlFor="textarea">Message</label>
                                <textarea id="textarea"  name="textarea"></textarea>
                            </div>
                            <div className="contact-el submit">
                                <input className="submit-btn" type="submit" value="Submit"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    );
}
export default ContactForm