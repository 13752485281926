import React from "react";


export default ({ data }) => {
    const {
        field_title_component_slide_item = [],
        field_text_comp_slide_item = [],
        field_pos_component_slide_item = [],
        field_ath_component_slide_item = [],
    } = data || {};

    const [title] = field_title_component_slide_item;
    const [text] = field_text_comp_slide_item;
    const [author] = field_ath_component_slide_item;
    const [position] = field_pos_component_slide_item;

    return (
        <div className="col-md-11">
            <div className="slider-left">
                {title !== undefined &&
                    <h2>{title.value}</h2>
                }
                {author !== undefined &&
                    <span className="slider-author">{author.value}</span>
                }
                {position !== undefined &&
                    <span className="slider-position-author">{position.value}</span>
                }
            </div>
            <div className="slider-right">
                {text !== undefined &&
                    <div dangerouslySetInnerHTML={{
                        __html: text.value
                    }}></div>
                }
            </div>
        </div>
    )
}
