import React from "react";
import DrupalImage from "./DrupalImage";


export default ({ data }) => {
    const {
        field_text_component_colum_item = [],
        field_img_component_colum_item = [],
        field_link_component_colum_item = []

    } = data || {};

    const [text] = field_text_component_colum_item;
    const [ imgData ] = field_img_component_colum_item;
    const uri = field_link_component_colum_item[0]?.uri;

    return (
        <div className="col-md-6 product-highlihts-features">
            <a href={uri}>
                {imgData !== undefined &&
                    <DrupalImage { ...imgData } />
                }
            </a>
            {text !== undefined &&
                <div
                    dangerouslySetInnerHTML={{
                        __html: text.value
                    }}>
                </div>
            }
    </div>
    )
}