import React from "react";
import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_text_comp_vpg_onboard = [],
        field_title_component_vpg_onboar = [],
        field_link_component_vpg_onboard = [],
        field_img_component_vpg_onboard = [],
    } = data || {};

    const [title] = field_title_component_vpg_onboar;
    const [text] = field_text_comp_vpg_onboard;
    const [link] = field_link_component_vpg_onboard;

    const [ imgData ] = field_img_component_vpg_onboard;
    return (
        <section className="container vpg-onboard">
            <div className="row">
                <div className="col-md-7">
                    {title !== undefined &&
                        <h2>{title.value}</h2>
                    }
                    {text !== undefined &&
                        <div dangerouslySetInnerHTML={{
                            __html: text.value
                        }}></div>
                    }
                    {link !== undefined &&
                        <a href={link.uri}>{link.title}</a>
                    }
                </div>
                <div className="col-md-5">
                    {imgData !== undefined &&
                        <DrupalImage { ...imgData } />
                    }
                </div>
            </div>
        </section>

);
}
