import React from "react"

import Social from "../components/Social";
import FieldComponent from "../components/FieldComponent";

export default ({ node }) => {
        const { field_component_markets = [] } = node;
        return (
            <>
                    {/*<Banner />*/}
                    <FieldComponent data={field_component_markets} />
                    {/*<OnboardSectionItems />*/}
                    {/*<EventNews />*/}
                    {/*<FourBlock />*/}
                    {/*<Slider />*/}
                    {/*<Social />*/}
            </>
        );
};

// const ProductPage = () => (
//     <Layout>
//
//         <SEO title="Management Page" />
//         <BannerImg img={managementBanner}/>
//         <BannerTitle title={"Wasted Management"} text={"The BinWeigh 03 system excels competition with proven best-in class hard VPG Onboard Weighing, market leader for legal-for-trade on-board weighing applications since more than three decades, and modern cloud services driven by Technexo Software"} bgcolor={'#F6F6F6'} />
//         <OverloadProtection />
//         <ManagementOnboard />
//         <ProductsAndSystems />
//
//         <FourBlock />
//         <OnboardSectionItems />
//         <Social />
//         <Slider />
//     </Layout>
// );
//
// export default ProductPage
