import React from "react"

export default ({ data, envName }) => {
    const {
        field_title_component_sal_of_it = [],
        field_text_comp_sal_of_it = [],
        field_link_component_sal_of_it = [],
        field_ph_component_sal_of_it = [],
        field_fax_component_sal_of_it = [],
    } = data || {};

    const [title] = field_title_component_sal_of_it;
    const [text] = field_text_comp_sal_of_it;
    const [link] = field_link_component_sal_of_it;
    const [ph] = field_ph_component_sal_of_it;
    const [fax] = field_fax_component_sal_of_it;
    const itemClassName = envName === 'si' ? 'offices-item offices-item-si' : 'offices-item';

    let itemTitle;

    if(undefined !== title) {
        if('si' === envName && link !== undefined && link.uri.indexOf('/internal') === -1 && title.value.indexOf('<span>') !== -1) {
            itemTitle = title.value.replace('<span', `<a href="${link.uri}"`);
            itemTitle = itemTitle.replace('</span>', '</a>');
        } else {
            itemTitle = title.value;
        }
    }
    return (
        <div className="col-md-3">
            <div className={itemClassName}>
                {title !== undefined &&
                    <h2 className="offices-item__title" dangerouslySetInnerHTML={{
                        __html: itemTitle
                    }}></h2>
                }
                {text !== undefined &&
                    <div className="offices-item-text" dangerouslySetInnerHTML={{
                    __html: text.value
                }}></div>
                }
                {(ph !== undefined || fax !== undefined) && <div className="office-item-phones">
                    {ph !== undefined &&
                        <p>TEL: {ph.value}</p>
                    }
                    {fax !== undefined &&
                        <p className="offices-item-fax">FAX: {fax.value}</p>
                    }
                </div>}
                {'si' !== envName && link !== undefined && link.uri.indexOf('/internal') === -1 &&
                    <a className="offices-link" href={link.uri}>{link.title}</a>
                }
            </div>
        </div>
    )
}
