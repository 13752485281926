import React from "react";
import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_title_component_event_it = [],
        field_text_comp_event_it = [],
        field_img_component_event_it = [],
        field_link_component_event_it = [],
        field_bg_color_comp_event_it = [],
        field_text_color_comp_event_it = [],
    } = data || {};

    const [title] = field_title_component_event_it;
    const [text] = field_text_comp_event_it;
    const [ imgData ] = field_img_component_event_it;
    const uri = field_link_component_event_it[0]?.uri;
    const  colorBg = field_bg_color_comp_event_it[0]?.value;
    const  colorText = field_text_color_comp_event_it[0]?.value;

    //console.log(field_link_component_event_it);
    //console.log(color);
    return (
        <a className="event-item event-item-link" href={uri} style={{backgroundColor: colorBg, color: colorText}}>
            {/*<div className="event-item">*/}

                <div className="text">
                    {title !== undefined &&
                        <h2>{title.value}</h2>
                    }
                    {text !== undefined &&
                        <div dangerouslySetInnerHTML={{
                            __html: text.value
                        }}></div>
                    }
                </div>
                {imgData !== undefined &&
                    <DrupalImage { ...imgData } />
                }
            {/*</div>*/}
        </a>
    )
}
