import React from "react";


export default ({ data }) => {
    const {
        field_title_component_title_div = [],
    } = data || {};
    const [title] = field_title_component_title_div;
    return (
        <section className="container sales-offices divider">
            <div className="row">
                <div className="col-md-12">
                    {title !== undefined &&
                        <h2>{title.value}</h2>
                    }
                </div>
            </div>
        </section>
    );
}