import React from "react"

import Banner from "../components/Banner"
import OnboardSection from "../components/OnboardSection"
import OnboardSectionItems from "../components/OnboardSectionItems"
import VpgOnboard from "../components/VpgOnboard"
import EventNews from "../components/EventNews"
import FourBlock from "../components/FourBlock"
import Slider from "../components/Slider"
import Social from "../components/Social"
import FieldComponent from "../components/FieldComponent";

export default ({ node }) => {
  const { field_component = [] } = node;
  return (
    <>
        {/*<Banner />*/}
        <FieldComponent data={field_component} />
        {/*<OnboardSectionItems />*/}
        {/*<EventNews />*/}
        {/*<FourBlock />*/}
        {/*<Slider />*/}
        {/*<Social />*/}
    </>
  );
};
