import React from "react";

import FourBlockItem from "../components/FourBlockItem";


export default ({ data }) => {
    const {
        field_items_component_four_block = [],
    } = data || {};

    const items = field_items_component_four_block.map((data, i) => <FourBlockItem key={i} data={data} />);
     // console.log(data);
    return (
        <section className="container four-block">
            <div className="row">
                {items}

                {/*<FourBlockItem title={'CASE STUDY'} text={'Lorem ipsum dolor sit amet, consectetur adipiscing'} />*/}
                {/*<FourBlockItem title={'CERTIFICATES'} text={'Lorem ipsum dolor sit amet, consectetur adipiscing'} color={'#d6ecfc'} />*/}
                {/*<FourBlockItem title={'VIDEOs'} text={'Lorem ipsum dolor sit amet, consectetur adipiscing'} color={'#F6F6F6'} />*/}
                {/*<FourBlockItem title={'MANUALS'} text={'Lorem ipsum dolor sit amet, consectetur adipiscing'} />*/}

            </div>
        </section>

    );
}