import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Slider from "../components/Slider";
import Social from "../components/Social";
import BannerImg from "../components/BannerImg";
import BannerTitle from "../components/BannerTitle";
import ContactForm from "../components/ContactForm";
import SalesOffices from "../components/SalesOffices";
import Representatives from "../components/Representatives";

import contactBanner from '../images/contact-banner.png';
import FieldComponent from "../components/FieldComponent";
import DrupalForm from "../components/DrupalForm";


export default ({ node, langCode }) => {
        const { field_component_contact = [] } = node;
        return (
            <>
                    <FieldComponent data={field_component_contact} langCode={langCode} />
                {/*<ContactForm title={"Inquiry form"} bgcolor={"#D6ECFC"} />*/}
                {/*    <Social />*/}
                {/*    <DrupalForm />*/}
            </>
        );
};

// const ContactPage = () => (
//     <Layout>
//         <SEO title="Contact Page" />
//         <BannerImg img={contactBanner}/>
//         <BannerTitle title={"Contact"} text={"he BinWeigh 03 system excels competition with proven best-in class hard VPG Onboard Weighing, market leader for legal-for-trade on-board weighing applications since more than three decades, and modern cloud services driven by Technexo Software in class hardware from VPG Onboard Weighing, market leader for legal-for-trade on-board weighing applications since more than three decades, and modern cloud services driven by Technexo Software "} bgcolor={'#F6F6F6'} />
//         <ContactForm title={"Inquiry form"} bgcolor={"#D6ECFC"} />
//         <SalesOffices />
//         <Representatives />
//         <Social />
//         <Slider />
//     </Layout>
// );
//
// export default ContactPage
