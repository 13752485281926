import React, { useState, useEffect } from "react";
import axios from 'axios';
const { GATSBY_API_URL = 'http://localhost' } = process.env;

export default () => {
    const [views, setViews] = useState([]);
    useEffect(() => {
        axios.get(`${GATSBY_API_URL}/news/json-block-home-page` )
            .then(res => {
                setViews(res.data);
            })
        },[axios, setViews]);
    const items = views.map((item, i) => {
        const href = item.view_node;
        const text = item.body_export;
        var cleanText = text?.replace(/<[^>]+>/g, '').substring(0, 135);
        const title = item.title.replace(/^(.{80}[^\s]*).*/, "$1");
        return (
            <div className="news-item">
                <a href={href}><h4>{title}</h4></a>
                {text !== null &&
                    <div dangerouslySetInnerHTML={{
                        __html: cleanText += '...'
                    }}></div>
                }
            </div>
        )
    });
    return  items
}
