import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

export default ({ target_id:fid }) => {
  if (!fid) {
    return null;
  }

  const data = useStaticQuery(graphql`
    query {
     file: allFileFile {
        edges {
          node {
            localFile {
              url
              name
            }
           drupal_internal__fid
          }
        }
      }
  }`);
  const file = data.file.edges.find(({ node: { drupal_internal__fid } }) => {
    return drupal_internal__fid + "" === fid + "";
  });
  const { node = {} } = file || {};
  const { localFile = {} } = node || {};
  const { url, name } = localFile || {};

  if (!url || !name) {
    return null;
  }
  return <a className={"file"} href={url} target='_blank'>{name}</a>;
}
