import React from "react";

import OnboardSectionItem from "../components/OnboardSectionItem"

export default ({ data }) => {
    const {
        field_item_component_onbd_items = [],
    } = data || {};

    const items = field_item_component_onbd_items.map((data, i) => <OnboardSectionItem key={i} data={data} />);

    return (
            <section className="onboard-section-item">
                <div className="container onboard-items">
                    <div className="row">
                        {items}
                    </div>
                </div>
            </section>
    );
}