import React from "react";
import DrupalImage from "./DrupalImage";

import managemantOnboard from '../images/management-onboard.png';
import Markets from "../templates/management-page";
import Layout from "./Layout";

export default ({ data }) => {
    const {
        field_title_component_onb_maneg	 = [],
        field_text_comp_onb_mane = [],
        field_link_i_component_onb_maneg = [],
        field_img_component_onb_maneg = [],
    } = data || {};

    const [title] = field_title_component_onb_maneg;
    const [text] = field_text_comp_onb_mane;
    const uri = field_link_i_component_onb_maneg[0]?.uri;
    const [ imgData ] = field_img_component_onb_maneg;
    return (



        <section className="container management-onboard">
            <div className="row">
                <div className="col-md-12">
                    <div className="management-onboard-text">
                        {title !== undefined &&
                            <h2>{title.value}</h2>
                        }
                        {text !== undefined &&
                            <div dangerouslySetInnerHTML={{
                                __html: text.value
                            }}></div>
                        }
                    </div>
                    {imgData !== undefined &&
                        <a href={uri}><DrupalImage {...imgData} style={{height: 'auto'}}/></a>
                    }
                </div>
            </div>
        </section>
    )
}