import React from "react"


import Social from "../components/Social"
import FieldComponent from "../components/FieldComponent";

export default ({ node }) => {
  const { field_component_basic_page = [] } = node;
  return (
    <>
        <FieldComponent data={field_component_basic_page} />
        {/*<Social />*/}
    </>
  );
};
