import React, {useState} from "react";

import FaqItem from "./FaqItem";

export default ({ data }) => {
    const [filter, setFilter] = useState('');

    const {
        field_items_component_faq = [],
    } = data || {};

    const items = field_items_component_faq.map((data, i) => <FaqItem key={i} filter={filter} data={data} />);

    return (
        <section className="container select-form faq-form">
            <div className="row">
                <div className="col-md-12">
                    <h2>Glossary</h2>
                    <form action="#">
                        <div className="search-faq">
                            <label>Term</label>
                            <input onChange={({target:{value}}) => setFilter(value)} type="text" />
                        </div>
                        <div className="select-faq-it">
                            {/*<label>Sort by</label>*/}
                            {/*<select>*/}
                            {/*    <option value="0">test1</option>*/}
                            {/*    <option value="1">test2</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="select-faq-it">
                            {/*<label>Order</label>*/}
                            {/*<select>*/}
                            {/*    <option value="0">test3</option>*/}
                            {/*    <option value="1">test4</option>*/}
                            {/*</select>*/}
                        </div>
                    </form>
                    {items}
                </div>
            </div>
        </section>

    );
}
