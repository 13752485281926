import React from "react";
import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_title_component_sub_cat_it = [],
        field_text_comp_sub_cat_it	 = [],
        field_link_component_sub_cat_it = [],
        field_img_component_sub_cat_it = [],
    } = data || {};

    const [title] = field_title_component_sub_cat_it;
    const [text] = field_text_comp_sub_cat_it	;
    const [link] = field_link_component_sub_cat_it;

    const [ imgData ] = field_img_component_sub_cat_it;
    return (
        <div className="col-md-3 onboard-item">
            {imgData !== undefined &&
                <DrupalImage { ...imgData } />
            }
            {title !== undefined &&
                <h6>{title.value}</h6>
            }
            {text !== undefined &&
                <div dangerouslySetInnerHTML={{
                    __html: text.value
                }}></div>
            }
            {link !== undefined &&
                <a href={link.uri}>{link.title}</a>
            }
        </div>
    )
}


