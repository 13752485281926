import React, {useState} from "react";

import searsh from '../images/news-icon-search.png';
import {graphql, useStaticQuery} from "gatsby";
import NewsBlockItems from "../components/NewsBlockItems";
import axios from "axios";
import DrupalImage from "./DrupalImage";
const { GATSBY_API_URL = 'http://localhost' } = process.env;

export default () => {
    const data = useStaticQuery(graphql`
        query {
          category: allNodeNews {
            nodes {
              field_category
            }
          }
        }`);

    const [ result, setResult ] = useState(null);
    const [active, setActive] = useState(null);

    const category = [];
    data.category.nodes.map((it, i) => category[i] = it.field_category);
    const uniqCategory = new Set(category);

    function handleClick(e, cat) {
        e.preventDefault();
        axios.get(`${GATSBY_API_URL}/news/news-page?category=${cat}`, {...data })
            .then((data) => {
                setActive(cat);
                setResult(data.data);
            })
    }

    return (
        <section className="container news">
            <div className="row news-tabs">
                <div className="col-md-12">
                    {result ?
                        Array.from(uniqCategory).map((itm, i) => itm == active ? <a href='#' className="news-link-active" onClick={e => handleClick(e, itm)}>{itm}</a> :<a href='#' onClick={e => handleClick(e, itm)}>{itm}</a>)
                        :
                        Array.from(uniqCategory).map((itm, i) => itm == "news" ? <a href='#' className="news-link-active" onClick={e => handleClick(e, itm)}>{itm}</a> :<a href='#' onClick={e => handleClick(e, itm)}>{itm}</a>)
                    }
                    {/*<a href='#'><img src={searsh} alt="" /></a>*/}
                </div>
            </div>
            <div className="row news-items">

                {result ?
                result.map((item, i)=> {
                    const href = item.view_node;
                    const img = item.field_img_prev;
                    const title = item.title.replace(/^(.{70}[^\s]*).*/, "$1");
                    return (
                        <div className="col-md-4">
                            {img &&
                                <img src={GATSBY_API_URL + img}/>
                            }
                            <a href={href}>{title}</a>
                        </div>
                    )
                }) : <NewsBlockItems />
                }
            </div>
        </section>

    );
}