import React from "react";

import EventItem from "../components/EventItem";
import NewsItem from "../components/NewsItem";

export default ({ data }) => {
    const {
        field_items_component_event = [],
    } = data || {};

    const items = field_items_component_event.map((data, i) => <EventItem key={i} data={data} />);
    return (
        <section className="container event-news">
            <div className="row">
                <div className="col-md-6 events">
                    {items}
                </div>
                <div className="col-md-6 news">
                    <h4>LATEST NEWS</h4>
                    <NewsItem />
                    <a href='/about/news-events-press' ><h4>MORE HEADLINES...</h4></a>
                </div>
            </div>
        </section>

);
}