import React from "react"

import Social from "../components/Social";
import FieldComponent from "../components/FieldComponent";




export default ({ node }) => {
        const { field_component_vehicles = [] } = node;
        return (
            <>
                    <FieldComponent data={field_component_vehicles} />
                    {/*<Social />*/}
            </>
        );
};
// const VehiclesPage = () => (
//     <Layout>
//         <SEO title="Vehicles Page" />
//         <BannerText img={vehiclesBanner} link={'#'} linktext={'See how we sarve this market'} title={'Product: truckweigh'} text={'Integrated weighing for continuous and precise weight information for waste and recycling.'} />
//         <BannerTitle title={"Vehicles \n Weighing solutuions for all transport"} text={"The BinWeigh 03 system excels competition with proven best-in class hard VPG Onboard Weighing, market leader for legal-for-trade on-board weighing applications since more than three decades, and modern cloud services driven by Technexo Software"} bgcolor={'#F6F6F6'} />
//         <VehiclesItems />
//         <VehiclesOnboard />
//         <FourBlock />
//         <Social />
//         <Slider />
//     </Layout>
// );
//
// export default VehiclesPage
