import React from "react";

import vihicle from '../images/vehicle-onboard-item.png';

import VehiclesOnboardItems from "../components/VehiclesOnboardItems"
import VihiclesItem from "./VihiclesItem";

export default ({ data }) => {
    const {
        field_items_component_two_col = [],
    } = data || {};

    const items = field_items_component_two_col.map((data, i) => <VehiclesOnboardItems key={i} data={data} />);
    return (
        <section className="container  vehicles-onboard">
            <div className="row">
                {items}
                {/*<VehiclesOnboardItems img={vihicle} title={'Overload protection'} text={'We understand that transport companies need to stay within safe operating tolerances of their vehicles while maximizing their efficiency. Aimed at the lighter commercial vehicles, our systems do just that.'} />*/}
                {/*<VehiclesOnboardItems img={vihicle} title={'Onboard weighing'} text={'We understand that transport companies need to stay within safe operating tolerances of their vehicles while maximizing their efficiency. Aimed at the lighter commercial vehicles, our systems do just that.'} />*/}
            </div>
        </section>
    );
}