import React from "react";


export default ({ data }) => {
    const {
        field_title_component_four_b_it = [],
        field_text_comp_four_b_it = [],
        field_bg_col_component_four_b_it = [],
        field_link_comp_four_b_it = [],
    } = data || {};

    const [title] = field_title_component_four_b_it;
    const [text] = field_text_comp_four_b_it;
    const [{ value:color = '' }] = field_bg_col_component_four_b_it;
    const uri = field_link_comp_four_b_it[0]?.uri;

    return (
        <div className="col-md-3">
            <a className="four-block-border" style={{backgroundColor: color}} href={uri}>
                {title !== undefined &&
                    <h2>{title.value}</h2>
                }
                {text !== undefined &&
                    <div dangerouslySetInnerHTML={{
                        __html: text.value
                    }}></div>
                }
            </a>
        </div>
    )
}

