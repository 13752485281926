import React from "react";

function BannerTitle({ title, text, bgcolor }) {
    return (
        <section className="banner-title" style={{backgroundColor: bgcolor}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{title}</h2>
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BannerTitle