import React from "react"


import Social from "../components/Social";
import FieldComponent from "../components/FieldComponent";
import BannerTitle from "../components/BannerTitle";
import SingleNewsBlock from "../components/SingleNewsBlock";



export default ({ node }) => {
    const {
        field_paragraph_bundle_about_ = [],
        title = []
    } = node;
    const [{ titles = '' }] = title[0].value;

    return (
        <>
            <FieldComponent data={field_paragraph_bundle_about_} />
            <BannerTitle title={title[0].value} />
            <SingleNewsBlock data={node} />
            {/*<Social />*/}
            </>
    );
};

